<template class="modal">
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="modal-content">
        <div v-for="item in items" :key="item.code">
          <div>
            <ion-button class="btn-video" @click="select(item.code)">{{ item.label }}</ion-button>
          </div>
        </div>
      </div>
      <div class="btn-close">
        <ion-button @click="close" color="tertiary" margin="20px"
        >Chiudi</ion-button
      >
      </div>
    </ion-content>
  </div>
</template>

<script>
export default {
  name: "ModalSelectVideo",
  props: ["items", "title"],
  data() {
    return {};
  },
  methods: {
    close() {
      this.$ionic.modalController.dismiss();
    },
    select(code){
      let videoSelected = new Event("videoSelected");
      videoSelected.value = code;
      videoSelected.message = "videoSelected";
      document.dispatchEvent(videoSelected);
      this.$ionic.modalController.dismiss();
    }
  },
};
</script>
<style scoped>
.modal-content {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.btn-video {
  white-space: normal;
  width: 150px;
  height: 150px;
}
.btn-close{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
