<template>
  <div class="ion-page">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <ion-header>
      <ion-toolbar color="primary-contrast">
        <div class="ion-text-center">
          <div class="header-logos">
            <ion-icon
              v-if="isOfflineReady"
              icon="md-radio-button-on"
              size="large"
              color="success"
            ></ion-icon>
            <ion-icon
              v-if="!isOfflineReady"
              icon="md-radio-button-off"
              size="large"
              color="danger"
            ></ion-icon>
            <ion-thumbnail class="logo-header">
              <img src="../assets/images/05_Logo_Ollomont.png" />
            </ion-thumbnail>
            <ion-thumbnail class="logo-header">
              <img src="../assets/images/Stemma_Valle_d'Aosta.png" />
            </ion-thumbnail>
            <ion-thumbnail class="logo-header" style="margin-top:-50px">
              <img
                src="../assets/images/logo_MINIERE_DI_OLLOMONT_VALPELLINE.png"
              />
            </ion-thumbnail>
            <ion-icon
              v-if="!isOfflineReady"
              icon="md-time"
              size="large"
              color="danger"
            ></ion-icon>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div
class="div-center"
      >
        <div v-show="numFile < 15">
          <ion-button
          @click="clearCache()"
          class="btn"
          color="tertiary"
          >Reset</ion-button
        >
        <ion-button
          @click="reload()"
          class="btn"
          color="tertiary"
          ><ion-icon
              icon="md-refresh"
              size="large"
            ></ion-icon></ion-button
        >
        </div>
      </div>
      <div
      class="div-center"
      >
        <ion-button
          @click="videoSelect(indexVideoSelected)"
          class="btn"
          color="tertiary"
          >Seleziona Video</ion-button
        >
      </div>

      <div style="display:flex; justify-content:center">
        <!-- Inizio Schede Video -->
        <video
          ref="video1"
          class="displayVideo"
          v-show="1 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/1_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video2"
          class="displayVideo"
          v-show="2 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/2_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video3"
          class="displayVideo"
          v-show="3 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/3_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video4"
          class="displayVideo"
          v-show="4 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/4_video.mp4" type="video/mp4" />
        </video>

        <video
          ref="video5"
          class="displayVideo"
          v-show="5 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/5_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video6"
          class="displayVideo"
          v-show="6 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/6_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video7"
          class="displayVideo"
          v-show="7 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/7_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video8"
          class="displayVideo"
          v-show="8 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/8_video.mp4" type="video/mp4" />
        </video>

        <video
          ref="video9"
          class="displayVideo"
          v-show="9 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/9_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video10"
          class="displayVideo"
          v-show="10 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/10_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video11"
          class="displayVideo"
          v-show="11 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/11_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video12"
          class="displayVideo"
          v-show="12 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/12_video.mp4" type="video/mp4" />
        </video>

        <video
          ref="video13"
          class="displayVideo"
          v-show="13 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/13_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video14"
          class="displayVideo"
          v-show="14 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/14_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video15"
          class="displayVideo"
          v-show="15 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/15_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video16"
          class="displayVideo"
          v-show="16 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/16_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video17"
          class="displayVideo"
          v-show="17 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/17_video.mp4" type="video/mp4" />
        </video>
        <video
          ref="video18"
          class="displayVideo"
          v-show="18 == selectedVideo"
          controls=""
          controlslist="nodownload"
          disablepictureinpicture
          @play="disableSelect()"
          @pause="disableSelect()"
        >
          <source src="../assets/videos/18_video.mp4" type="video/mp4" />
        </video>
        <!-- Fine Schede Video -->
      </div>
    </ion-content>
    <ion-footer class="ion-text-center">
      <img
        class="logo-footer"
        src="../assets/images/logo_interreg_minove.png"
      />
    </ion-footer>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
//Import components
import Modal from "./Modal.vue";
import ModalSelectVideo from "./ModalSelectVideo.vue";

export default {
  name: "AppVideo",
  props: {
    msg: String,
  },
  data: function() {
    return {
      isLoading: false,
      fullPage: true,
      searchText: "",
      videoElements: [
        { code: 1, name: "video1" },
        { code: 2, name: "video2" },
        { code: 3, name: "video3" },
        { code: 4, name: "video4" },
        { code: 5, name: "video5" },
        { code: 6, name: "video6" },
        { code: 7, name: "video7" },
        { code: 8, name: "video8" },
        { code: 9, name: "video9" },
        { code: 10, name: "video10" },
        { code: 11, name: "video11" },
        { code: 12, name: "video12" },
        { code: 13, name: "video13" },
        { code: 14, name: "video14" },
        { code: 15, name: "video15" },
        { code: 16, name: "video16" },
        { code: 17, name: "video17" },
        { code: 18, name: "video18" },
      ],
      videos: [
        {
          label: "Ollomont e la sua miniera",
          code: 1,
          name: "1_video",
          isDownloaded: false,
        },
        {
          label: "Ollomont and its mine",
          code: 2,
          name: "2_video",
          isDownloaded: false,
        },
        {
          label: "Ollomont et sa mine",
          code: 3,
          name: "3_video",
          isDownloaded: false,
        },
        {
          label: "La miniera di Ollomont: 250 anni di attività",
          code: 4,
          name: "4_video",
          isDownloaded: false,
        },
        {
          label: "The Ollomont mine: 250 years of activity",
          code: 5,
          name: "5_video",
          isDownloaded: false,
        },
        {
          label: "Le mine d'Ollomont: 250 ans d'activité",
          code: 6,
          name: "6_video",
          isDownloaded: false,
        },
        {
          label: "La calcopirite, un minerale di rame",
          code: 7,
          name: "7_video",
          isDownloaded: false,
        },
        {
          label: "Chalcopyrite, a copper ore",
          code: 8,
          name: "8_video",
          isDownloaded: false,
        },
        {
          label: "Chalcopyrite, un mineral de cuivre",
          code: 9,
          name: "9_video",
          isDownloaded: false,
        },
        {
          label: "L'estrazione della calcopirite",
          code: 10,
          name: "10_video",
          isDownloaded: false,
        },
        {
          label: "The mining of chalcopyrite",
          code: 11,
          name: "11_video",
          isDownloaded: false,
        },
        {
          label: "L'exploitation de la chalcopyrite",
          code: 12,
          name: "12_video",
          isDownloaded: false,
        },
        {
          label: "L'oro di Ollomont",
          code: 13,
          name: "13_video",
          isDownloaded: false,
        },
        {
          label: "The gold of Ollomont",
          code: 14,
          name: "14_video",
          isDownloaded: false,
        },
        {
          label: "L'or d'Ollomont",
          code: 15,
          name: "15_video",
          isDownloaded: false,
        },
        {
          label:
            "Dal legno al carbone / Du bois au charbon / From wood to charcoal",
          code: 16,
          name: "16_video",
          isDownloaded: false,
        },
        {
          label: "La fusione dei metalli",
          code: 17,
          name: "17_video",
          isDownloaded: false,
        },
        {
          label: "Don Agostino Pession",
          code: 18,
          name: "18_video",
          isDownloaded: false,
        },
      ],
      selectedVideo: null,
      isPlaying: true,
      isOfflineReady: false,
      numFile: 0,
      downloaded: [],
      intervalValue: null,
    };
  },
  components: {
    Loading,
  },

  mounted() {
    document.addEventListener("cachedEvent", this.handleCustomEvent);
    document.addEventListener("readyEvent", this.handleCustomEvent);
    document.addEventListener("downloadingEvent", this.handleCustomEvent);
    document.addEventListener("videoSelected", this.handleCustomEvent);
    this.intervalValue = setInterval(
      this.countDownloadedMp4File.bind(this),
      3000
    );
    this.countCachedMP4Files();
  },
  beforeUnmount() {
    document.removeEventListener("cachedEvent", this.handleCustomEvent);
    document.removeEventListener("readyEvent", this.handleCustomEvent);
    document.addEventListener("downloadingEvent", this.handleCustomEvent);
  },
  methods: {
    reload(){
      window.location.reload();
    },
    videoSelect(index) {
      this.videoElements.forEach((videoElement) => {
        if (!index) {
          index = 0;
        }
        let name = videoElement.name;
        if (videoElement.code == index) {
          let videoTag = this.$refs[name];
          videoTag.pause();
          videoTag.currentTime = 0;
        }
      });
      this.createModal(ModalSelectVideo, "Lista dei video:", this.videos,true);
    },
    countCachedMP4Files() {
      //la stringa rappresenta il nome della preCache autogenerato da Workbox
      //questa parte è sempre fissa workbox-precache-v2-
      //va concatenato base Url
      caches
        .open("workbox-precache-v2-" + process.env.VUE_APP_BASE_URL)
        .then((cache) => {
          cache.keys().then((keys) => {
            const mp4Files = keys.filter((request) =>
              request.url.includes(".mp4")
            );
            this.numFile = mp4Files.length;
            //genera un evento se il numero di file corrisponde al numero di video caricati
            if (this.numFile == 18) {
              clearInterval(this.intervalValue);
              let readyEvent = new Event("readyEvent");
              readyEvent.value = "readyEvent";
              document.dispatchEvent(readyEvent);
            }
          });
        });
    },
    //conta i file Mp4 che sono stati scaricati
    countDownloadedMp4File() {
      caches
        .open("workbox-precache-v2-" + process.env.VUE_APP_BASE_URL)
        .then((cache) => {
          cache.keys().then((keys) => {
            const mp4Files = keys.filter((request) =>
              request.url.includes(".mp4")
            );
            let downloadedFile = new Array(18);
            mp4Files.forEach((mp4File) => {
              for (let i = 0; i < 18; i++) {
                if (mp4File.url.includes(this.videos[i].name)) {
                  downloadedFile[i] = this.videos[i].code;
                }
              }
            });
            this.downloaded = downloadedFile;
          });
        });
      let count = 0;
      this.videos.forEach((video) => {
        if (video.isDownloaded) {
          count++;
        }
      });
      if (count == 18) {
        window.location.reload();
      }
    },
    presentAlert(header, subtitle, message) {
      return this.$ionic.alertController
        .create({
          header: header,
          subHeader: subtitle,
          message: message,
          buttons: ["OK"],
          backdropDismiss: false,
        })
        .then((a) => a.present());
    },
    handleCustomEvent(event) {
      if (event.value == "cachedEvent") {
        window.location.reload();
      } else if (event.value == "readyEvent") {
        this.isOfflineReady = true;
        this.presentAlert(
          "messaggio",
          "",
          "Il dispositivo può essere utilizzato offline"
        );
      } else if (event.value == "downloadingEvent") {
        this.createModal(Modal, "Lista dei video:", this.videos,false);
      } else if (event.message == "videoSelected") {
        this.selectedVideo = event.value;
        this.videoElements.forEach((videoElement) => {
          let name = videoElement.name;
          if (videoElement.code == this.selectedVideo) {
            let videoTag = this.$refs[name];
            videoTag.currentTime = 0;
            videoTag.play();
          }
        });
      }
    },
    clearCache() {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          if (cacheName.startsWith("workbox")) {
            caches.delete(cacheName);
            sessionStorage.clear();
            localStorage.clear();
          }
        });
      });
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      }
    },
    disableSelect() {
      if (this.isPlaying) {
        this.isPlaying = false;
      } else {
        this.isPlaying = true;
      }
    },
    isDisabled() {
      if (this.isPlaying) return true;
      else return false;
    },
    selected(e, selected) {
      if (selected) {
        e.target.style.backgroundColor = "#DB3939";
        e.target.style.color = "#FFFFFF";
      } else {
        e.target.style.backgroundColor = "#FFFFFF";
        e.target.style.color = "#000000";
      }
    },
    createModal(component, title, items,backdrop) {
      return this.$ionic.modalController
        .create({
          component: component,
          componentProps: {
            data: {},
            propsData: {
              title: title,
              items: items,
            },
          },
          backdropDismiss: backdrop,
        })
        .then((m) => m.present());
    },
  },
  computed: {
    indexVideoSelected() {
      return this.selectedVideo;
    },
  },
  watch: {
    downloaded: {
      handler() {
        this.downloaded.forEach((oldv) => {
          this.videos.forEach((video) => {
            if (video.code == oldv) {
              video.isDownloaded = true;
            }
          });
        });
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
}
.btn {
  margin-top: 20px;
}
.receiver-link-card {
  --background-activated: #db3939;
  color: #ffffff;
}
ion-card-content {
  color: #000000;
}
ion-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
ion-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.logo-header {
  width: 100px;
  height: 100px;
}
.logo-footer {
  width: 500px;
}
.header-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-header-3 {
  width: 150px;
  margin-top: -90px;
}
.logo-header-2 {
  width: 100px;
}
.logo-header-1 {
  width: 225px;
}
.download-again-button {
  display: flex;
  justify-content: center;
}
.displayVideo {
  width: 700px;
  margin-inline: 37%;
}
.selectVideo {
  margin: auto;
  width: 300px;
}
.btn {
  width: 45%;
}
.div-center{
   display: flex;
  justify-content: center; 
}
</style>
