<template class="modal">
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="modal-content">
        <div v-for="item in items" :key="item.code">
        <div v-if="item.isDownloaded">
          <ion-text color="success"> {{ item.label }}</ion-text>
          <!-- <ion-icon
            color="success"
            icon="checkmark"
            size="large"
          ></ion-icon> -->
        </div>
        <div v-else>
          <ion-text color="danger"> {{ item.label }}</ion-text>
          <!-- <ion-icon
            name="close"
            size="large"
            color="danger"
          ></ion-icon> -->
        </div>
      </div>
      <ion-button @click="close" color="tertiary" margin="20px">Chiudi</ion-button>
      </div>
    </ion-content>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["items", "title"],
  data() {
    return {
    }
  },
  methods: {
    close() {
      this.$ionic.modalController.dismiss()
    },
  },
};
</script>
<style scoped>
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>